<template>
  <div class="main_content align-items-center main_selfassesment program_overview">
    <div class="top_heading mb_50">
      <div class="row justify-content-xl-between align-items-start">
        <div class="col-12 col-md-12 col-xl-5 col-xxl-5">
          <div class="heading padd_ipad">
            <h2>Ergo Program Overview</h2>
            <h3 v-if="!this.urlClientId" >Program Manager Dashboard </h3> 
            <h3 v-else class="Program_dashboard_head">
              <router-link class="text-darks hov_router" :to="{name: 'client_manager'}"> Program Manager Dashboard </router-link> /
              <router-link class="text-darks hov_router" :to="{name: 'client_manager'}"> Program Management </router-link>
            </h3>
          </div>
        </div>
        <div class="col-12 col-md-12 col-xl-7 col-xxl-7">
          <div class="align-items-center d-flex justify-content-end w-100">
            <div class="date_toolbar_main right_top_min">
              <div class="bg-white date-toolbar region_bar_add wdth_mang">
                <div class="row">
                  <div class="col-xs-12 col-md-12 col-lg-9 align-self-center">
                    <div class="">
                      <div class="bg-white date-toolbar" style="box-shadow: none">
                        <div class="row">
                          <div class="col-xs-12 col-md-12 align-self-center">
                            <div class="row">
                              <div class="col-xs-12 col-md-12 col-lg-6">
                                <div class="date">
                                  <div class="heading_5">From</div>
                                  <div class="text_black font_size_14 d-flex align-items-center">
                                    <span>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                        viewBox="0 0 17.5 17.5">
                                        <path data-name="Path 131" d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z" transform="translate(-2.25 -2.25)" style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                                      </svg>
                                    </span>
                                    <datepicker placeholder="MM DD, YYYY" v-model="listConfig.start_date"
                                      :format="customFormatter" @selected="changeStartDate"
                                      :disabledDates="disabledFutureDates"></datepicker>
                                    <p class="min-date-value d-none">
                                      {{ listConfig.start_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xs-12 col-md-12 col-lg-6">
                                <div class="date todate pl_6">
                                  <div class="heading_5">To</div>
                                  <div class="text_black font_size_14 d-flex align-items-center">
                                    <span>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                        viewBox="0 0 17.5 17.5">
                                        <path data-name="Path 131" d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z" transform="translate(-2.25 -2.25)" style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                                      </svg>
                                    </span>
                                    <datepicker placeholder="MM DD, YYYY" v-model="listConfig.end_date"
                                      :format="customFormatter" @selected="changeEndDate" :disabled="isDisabled"
                                      :disabledDates="disabledDates"></datepicker>
                                    <p class="max-date-value d-none">
                                      {{ listConfig.end_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-12 col-lg-3 align-self-center">
                    <div class="form-group for_ipad">
                      <v-select @input="filterDataWithRegistrant" :reduce="(region_list) => region_list.id" :options="region_list"
                        label="name" placeholder="By Region" name="Region" v-model="listConfig.regionBy"
                        v-validate="'required'" browserAutocomplete="false" autocomplete="off" />
                      <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                        {{ errors.first("addUserForm.Region") }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript:" class="reset_btn" @click="resetDatesMain">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <metadata>
                  Svg Vector Icons : http://www.onlinewebfonts.com/icon
                </metadata>
                <g>
                  <path
                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="most_visited self_assesment">
      <div class="row">
        <div class="col-md-12 col-xl-6 col-xxl-6">
          <div class="row">
            <div class="col-xs-12 col-md-12 col-lg-12 col-xxl-12 lg-padding">
              <div class="align-items-stretch both_card d-lg-flex justify-content-between">
                <div class="card_box green_card mr_12">
                  <router-link :to="{
                      name: 'dashboard_survey_reports_SA',
                    }" target="_blank">
                    <img :src="require('@/assets/images/self_user.png')" alt="">
                    <h3>Completed Self- <br> Assessments</h3>
                    <div class="flex">
                      <h2>{{completed_self_assessments}}</h2>
                    </div>
                    <img :src="require('@/assets/images/chart_green.png')" alt="" class="bottom_graph">
                  </router-link>
                </div>
                <div class="card_box yellow_card mr_12">
                  <router-link :to="{
                      name: 'dashboard_survey_reports_EE',
                    }" target="_blank">
                    <img :src="require('@/assets/images/ui-part.png')" alt="">
                    <h3>Completed 1:1 <br> Evaluations </h3>
                    <div class="flex">
                      <h2>{{complete_evaluations}}</h2>
                    </div>
                    <img :src="require('@/assets/images/yellow_chart.png')" alt="" class="bottom_graph">
                  </router-link>
                </div>
                <div class="card_box orange_card">
                  <router-link :to="{
                    name: 'dashboard_scheduled_requests',
                      params: { type: 'Evaluations-Requested' },
                    }" target="_blank">
                    <img :src="require('@/assets/images/notification_well.png')" alt="">
                    <h3>Evaluations <br> Requested </h3>
                    <div class="flex">
                      <h2>{{evaluations_requested}}</h2>
                    </div>
                    <img :src="require('@/assets/images/chart_oange.png')" alt="" class="bottom_graph">
                  </router-link>
                </div>
              </div>
            </div>
            <!-- <div class="col-xs-12 col-md-5 col-lg-5 col-xxl-5 mt-0 position-relative">
              <div style="position: absolute;left: 0;right: 0;top: 0;bottom: 0;">
                <Spinner ref="Spinner"></Spinner>
             </div>
              <div class="card_box big_card h-100 most_visited visit_box">
                <h2 class="sml_visit">Most Visited Pages</h2>
               <div v-show="showMostViewedContent">  
                <div v-if="most_visited_pages.length" class="registrant visit_scroll cust_scroll mt_20">
                  <div v-for="(mvp, indx) in most_visited_pages" :key="indx" class="d-flex justify-content-between registrant_section">
                    <div class="name">
                      <h3 v-tooltip="mvp.name"> {{mvp.name}}</h3>
                    </div>
                    <div class="percent">
                      <h3>({{mvp.value}})</h3>
                    </div>
                  </div>
                </div>
                <div v-else class="bar_graph mb_34 white_card border_radius_20 view_no_data mt-3">
                  <h2 class="no_data">No Data Found</h2>
                </div>
                </div>
              </div>
            </div> -->
            <div class="col-xs-12 col-md-12 col-lg-12 col-xxl-12 mt-4 p-mitigration">
              <div class="white_card big_card h-100 position-relative">
                <h2 class="p-3">Mitigation & Cost Savings</h2>
                <img  v-tooltip="{ ref: 'tooltipRef' }" :src="require('@/assets/images/info.png')" class="info_icon" alt="" />
                <div ref="tooltipRef" class="tooltip-content">
                  <p>1. Site to 1:1 Eval = Client users count - Users count with finalized ergo eval reports</p>
                  <p>2. Self-Assessment to Eval = Users count with self assessment attempted - Users count with finalized ergo eval reports.</p>
                </div>
                <!-- <img
                  v-tooltip=" 'Mitigation Rate = Total company ergo cost savings through employee site use, which preempts an employees need to progress from self-assessment to 1:1 ergo evaluation.'"
                  :src="require('@/assets/images/info.png')" class="info_icon" alt="" title=""> -->
                <hr style="background:#D6D5D5;margin-bottom:0;">
                <div class="mitigation border-dot px-3 py-3">
                  <div class="d-flex align-items-center">
                    <div class="donut_chart">
                      <doughnut-chart :width="80" :height="80" :chart-data="mitisitedatacollection"
                        ref="appendMitiSite"></doughnut-chart>
                    </div>
                    <div class="d-lg-block d-xxl-flex justify-content-between w-100">
                      <div class="miti-details ">
                        <h2 v-tooltip="'Site to 1:1 Eval'" class="miti-sub_head">Site to 1:1 Eval</h2>
                      </div>
                      <div class="tab_report toggle_switch">
                        <ul id="myTabMitiSite" role="tablist" class="nav nav-tabs">
                          <li role="presentation" class="nav-item"><button id="percent-tabMitiSite" data-bs-toggle="tab"
                              data-bs-target="#percentMitiSite" type="button" role="tab" aria-controls="percent"
                              aria-selected="true" class="nav-link active">%</button></li>
                          <li role="presentation" class="nav-item"><button id="hash-tabMitiSite" data-bs-toggle="tab"
                              data-bs-target="#hashMitiSite" type="button" role="tab" aria-controls="hash"
                              aria-selected="false" class="nav-link">#</button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-start new_mitigation">
                    <div class="tab-content" id="myTabMitiSiteContent">
                      <div class="tab-pane fade show active" id="percentMitiSite" role="tabpanel"
                        aria-labelledby="percent-tabMitiSite">
                        <h3 class="per_mini">{{ mitigation_site_percent }}%</h3>
                      </div>
                      <div class="tab-pane fade" id="hashMitiSite" role="tabpanel" aria-labelledby="hash-tabMitiSite">
                        <h3 class="per_mini ms-3">{{ mitigation_site_count }}</h3>
                      </div>
                    </div>
                    <h2 class="miti_cost move_usd">Cost Savings <strong v-tooltip="'$'+ mitigation_site_cost +' USD'">${{ mitigation_site_cost }} USD</strong></h2>
                  </div>
                </div>
                <div class="mitigation px-3 py-3">
                  <div class="d-flex align-items-center">
                    <div class="donut_chart">
                      <doughnut-chart :width="80" :height="80" :chart-data="mitiselfdatacollection"
                        ref="appendMitiSelf"></doughnut-chart>
                    </div>
                    <div class="d-lg-block d-xxl-flex justify-content-between w-100">
                      <div class="miti-details wd_mini">
                        <h2 v-tooltip="'Self-Assessment to Eval'" class="miti-sub_head">Self-Assessment to Eval</h2>
                      </div>
                      <div class="tab_report toggle_switch">
                        <ul id="myTabMitiSelf" role="tablist" class="nav nav-tabs">
                          <li role="presentation" class="nav-item"><button id="percent-tabMitiSelf" data-bs-toggle="tab"
                              data-bs-target="#percentMitiSelf" type="button" role="tab" aria-controls="percent"
                              aria-selected="true" class="nav-link pd_mini_btn active">%</button></li>
                          <li role="presentation" class="nav-item"><button id="hash-tabMitiSelf" data-bs-toggle="tab"
                              data-bs-target="#hashMitiSelf" type="button" role="tab" aria-controls="hash"
                              aria-selected="false" class="nav-link pd_mini_btn">#</button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-start new_mitigation">
                    <div class="tab-content" id="myTabMitiSelfContent">
                      <div class="tab-pane fade show active" id="percentMitiSelf" role="tabpanel"
                        aria-labelledby="percent-tabMitiSelf">
                        <h3 class="per_mini">{{ mitigation_selfAssessment_percent }}%</h3>
                      </div>
                      <div class="tab-pane fade" id="hashMitiSelf" role="tabpanel" aria-labelledby="hash-tabMitiSelf">
                        <h3 class="per_mini ms-3">{{ mitigation_selfAssessment_count }}</h3>
                      </div>
                    </div>
                    <h2 class="miti_cost move_usd">Cost Savings <strong v-tooltip="'$'+ mitigation_selfAssessment_cost +' USD'" >${{ mitigation_selfAssessment_cost }} USD</strong></h2>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xl-6 col-xxl-6 for-notepad for_no_top" ref="SiteRegistrantsDiv">
          <div class="registrtans_site_main white_card border-radius-20 h-100">
            <div class="registrtans_site pad_bottom_non bot_padd activity_region">
              <div class="row align-items-center">
                <div class="col-8 col-sm-6">
                  <h2 class="mb_20">Site Registrants</h2>
                </div>
                <div class="col-4 col-sm-6">
                  <div class="d-flex justify-content-end align-items-baseline">
                    <div class="d-none visited_pages mr_12">
                      <div class="heading">
                        <div class="form-group by_min">
                          <v-select @input="filterSiteRegistrants" :reduce="(region_list) => region_list.id"
                            :options="region_list" label="name" placeholder="By Region" name="Region"
                            v-model="listConfig.site_regionBy" v-validate="'required'" browserAutocomplete="false" autocomplete="off" />
                          <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                            {{ errors.first("addUserForm.Region") }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div v-if="hideSiteRegistrantsDownloadBtn" class="align-items-center d-flex justify-content-end">
                      <div class="ml_24" data-html2canvas-ignore="true">
                        <a v-if="RegistrantsUnique && site_registrantsUnique.length" href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('SiteRegistrantsDiv', 'Current-Site-Registrants', '', '', '', site_registrantsUnique)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g data-name="Group 12689">
                              <path data-name="Path 114"
                                d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                transform="translate(-3 -3)"
                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                            </g>
                          </svg>
                        </a>
                        <a v-if="RegistrantsRevisits && site_registrantsRevisits.length" href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('SiteRegistrantsDiv', 'YTD-Site-Registrants', '', '', '', site_registrantsRevisits)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g data-name="Group 12689">
                              <path data-name="Path 114"
                                d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                transform="translate(-3 -3)"
                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                            </g>
                          </svg>
                        </a>
                        <a v-if="allTimeRegistrants" href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('SiteRegistrantsDiv', 'All-Time-Site-Registrants', '', '', '', allTimeSiteRegistrant)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g data-name="Group 12689">
                              <path data-name="Path 114"
                                d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                transform="translate(-3 -3)"
                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                            </g>
                          </svg>
                        </a>
                      </div>
                      <div class="d-none dropdown calender_dropdown align-self-center">
                        <a href="javascript:void(0)" id="dropdownMenuLink1" data-bs-toggle="dropdown"
                          aria-expanded="false" class="ml_20 dropdown-toggle" role="button" data-bs-auto-close="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path data-name="Path 130"
                              d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                              transform="translate(-2 -2)"
                              style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                          </svg>
                        </a>
                        <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                          aria-labelledby="dropdownMenuLink1">
                          <div class="date pl_6">
                            <div class="d-flex justify-content-between align-items-center">
                              <div class="heading_5 font_bold mb_10">From</div>
                              <a href="javascript:void(0)" class="reset_btn" @click="resetDatesSite">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                  version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000"
                                  enable-background="new 0 0 1000 1000" xml:space="preserve">
                                  <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                  <g>
                                    <path
                                      d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                  </g>
                                </svg>
                              </a>
                            </div>
                            <div class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                              <span class="mr_10">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                  <path data-name="Path 130"
                                    d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                    transform="translate(-2 -2)"
                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                </svg>
                              </span>
                              <datepicker placeholder="MM DD, YYYY" v-model="listConfig.site_sdate"
                                :format="customFormatter" @selected="changeStartDateSite"
                                :disabledDates="disabledFutureDates"></datepicker>
                              <p class="min-date-value d-none">
                                {{ listConfig.site_sdate | formatDate }}
                              </p>
                            </div>
                          </div>
                          <div class="date pl_6 mt_20">
                            <div class="heading_5 font_bold mb_10">To</div>
                            <div class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                              <span class="mr_10">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                  <path data-name="Path 130"
                                    d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                    transform="translate(-2 -2)"
                                    style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                </svg>
                              </span>
                              <datepicker placeholder="MM DD, YYYY" v-model="listConfig.site_edate"
                                :format="customFormatter" @selected="changeEndDateSite" :disabled="isDisabledSite"
                                :disabledDates="disabledDates"></datepicker>
                              <p class="max-date-value d-none">
                                {{ listConfig.site_edate | formatDate }}
                              </p>
                            </div>
                          </div>
                          <div class="button mt_30">
                            <a href="javascript:" @click="updateSiteRegistrants"
                              class="btn btn_primary px_14">Update</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4">
              <div class="d-lg-flex align-items-center justify-content-between">
                <div class="align-items-center d-flex justify-content-lg-between justify-content-center">
                  <img :src="require('@/assets/images/resistant.svg')">
                  <h3 v-if="allTimeRegistrants">All Time Registrants</h3>
                  <h3 v-if="RegistrantsUnique">Current Users</h3>
                  <h3 v-if="RegistrantsRevisits"> YTD (year to date)</h3>
                  <h4>
                      <span v-if="allTimeRegistrants">{{allTimeSiteRegistrant_count > 0 ? allTimeSiteRegistrant_count : ''}}</span>
                      <span v-if="RegistrantsUnique"> {{site_registrants_unique}} </span> 
                      <span v-if="RegistrantsRevisits"> {{site_registrants_revisits}} </span> 
                      <img :src="require('@/assets/images/up_arrow.svg')" class="img-arrow">
                  </h4>
                </div>
                <div>
                  <div class="tab_report toggle_switch my-3 my-lg-0 text-center">
                    <ul id="myTabSite" role="tablist" class="nav nav-tabs">
                      <li role="presentation" @click="showAllTimeRegistrants(), downloadSiteRegistrantsBtn(allTimeSiteRegistrant)" class="nav-item">
                        <button id="allpercent-tabSite" data-bs-toggle="tab" data-bs-target="#allpercentSite" type="button" role="tab" aria-controls="percent" aria-selected="true" class="nav-link active">All Time</button>
                      </li>
                      <li role="presentation" @click="showRegistrantsUnique(), downloadSiteRegistrantsBtn(site_registrantsUnique)" class="nav-item">
                        <button id="percent-tabSite" data-bs-toggle="tab" data-bs-target="#percentSite" type="button" role="tab" aria-controls="percent" aria-selected="true" class="nav-link">Current</button>
                      </li>
                      <li role="presentation" @click="showRegistrantsRevisits(), downloadSiteRegistrantsBtn(site_registrantsRevisits)" class="nav-item">
                        <button id="hash-tabSite" data-bs-toggle="tab" data-bs-target="#hashSite" type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link">YTD</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="tab-content" id="myTabSiteContent">
                <div class="tab-pane fade show active" id="allpercentSite" role="tabpanel" aria-labelledby="allpercent-tabSite">
                  <div v-if="allTimeSiteRegistrant.length"  class="line_chart">
                    <line-chart :height="320" :chart-data="allTimeSiteRegistrantsCollection" ref="allTimeSiteRef"></line-chart>
                  </div>
                  <div v-else class="line_chart line_nodata">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
                <div class="tab-pane fade" id="percentSite" role="tabpanel" aria-labelledby="percent-tabSite">
                  <div v-if="site_registrantsUnique.length" class="line_chart">
                    <line-chart :height='320' :chart-data="siteUniqueCollection" ref="appendSiteUnique"></line-chart>
                  </div>
                  <div v-else class="line_chart line_nodata">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
                <div class="tab-pane fade" id="hashSite" role="tabpanel" aria-labelledby="hash-tabSite">
                  <div v-if="site_registrantsRevisits.length" class="line_chart">
                    <line-chart :height='320' :chart-data="siteRevisitsCollection" ref="appendSiteRevisits"></line-chart>
                  </div>
                  <div v-else class="line_chart line_nodata">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="self_report mt-0">
      <div class="row">
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-5" ref="TotalActivityByRegionDiv">
          <div class="activity_region ">
            <div class="row align-items-baseline">
              <div class="col-8 col-sm-6 px-sm-4 px-lg-2">
                <h2 class="mb_20">Total Activity</h2>
              </div>
              <div class="col-4 col-sm-6">
                <div class="d-flex justify-content-end align-items-baseline">
                  <div class="d-none visited_pages mr_12">
                    <div class="heading">
                      <div class="form-group by_min">
                        <v-select :reduce="(region_list) => region_list.id"
                          :options="region_list" label="name" placeholder="By Region" name="Region"
                          v-model="listConfig.region_regionBy" v-validate="'required'" browserAutocomplete="false" autocomplete="off"/>
                        <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                          {{ errors.first("addUserForm.Region") }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div v-if="hideActivityRegionDownloadBtn" class="d-flex justify-content-end">
                    <div class="ml_24" data-html2canvas-ignore="true">
                      <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('TotalActivityByRegionDiv', 'Total-Activity', '500px', '312px', 'appendPieChartHeight', activity_region)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                          <g data-name="Group 12689">
                            <path data-name="Path 114"
                              d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                              transform="translate(-3 -3)"
                              style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                          </g>
                        </svg>
                      </a>
                    </div>
                    <div class="d-none dropdown calender_dropdown align-self-center">
                      <a href="javascript:void(0)" id="dropdownMenuLink2" data-bs-toggle="dropdown" aria-expanded="false"
                        class="ml_20 dropdown-toggle" role="button" data-bs-auto-close="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                          <path data-name="Path 130"
                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                            transform="translate(-2 -2)"
                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                        </svg>
                      </a>
                      <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                        aria-labelledby="dropdownMenuLink2">
                        <div class="date pl_6">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="heading_5 font_bold mb_10">From</div>
                            <a href="javascript:" class="reset_btn" @click="resetDatesRegion">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000"
                                enable-background="new 0 0 1000 1000" xml:space="preserve">
                                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                <g>
                                  <path
                                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                </g>
                              </svg>
                            </a>
                          </div>
                          <div class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                            <span class="mr_10">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path data-name="Path 130"
                                  d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                  transform="translate(-2 -2)"
                                  style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                              </svg>
                            </span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.region_sdate"
                              :format="customFormatter" @selected="changeStartDateRegion"
                              :disabledDates="disabledFutureDates"></datepicker>
                            <p class="min-date-value d-none">
                              {{ listConfig.region_sdate | formatDate }}
                            </p>
                          </div>
                        </div>
                        <div class="date pl_6 mt_20">
                          <div class="heading_5 font_bold mb_10">To</div>
                          <div class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                            <span class="mr_10">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path data-name="Path 130"
                                  d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                  transform="translate(-2 -2)"
                                  style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                              </svg>
                            </span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.region_edate"
                              :format="customFormatter" @selected="changeEndDateRegion" :disabled="isDisabledRegion"
                              :disabledDates="disabledDates"></datepicker>
                            <p class="max-date-value d-none">
                              {{ listConfig.region_edate | formatDate }}
                            </p>
                          </div>
                        </div>
                        <div class="button mt_30">
                          <a href="javascript:" @click="updateActivityRegion" class="btn btn_primary px_14">Update</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mapping appendPieChartHeight">
            <div v-if="activity_region.length" class="row align-items-center">
              <div class="col-md-7 text-center">
                <div class="pieIchart_2 mt_60">
                  <pie-chart :height="350"  :chart-data="piechartdatacollection" ref="appendPieChart"></pie-chart>
                </div>
              </div>
              <div class="col-md-5">
                <div class="row mb_32 justify-content-end" data-html2canvas-ignore="true">
                  <div class="col-9 text-end">
                    <div class="tab_report toggle_switch">
                      <ul class="nav nav-tabs" id="myTab2" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="percent-tab2" data-bs-toggle="tab"
                            data-bs-target="#percent2" type="button" role="tab" aria-controls="percent"
                            aria-selected="true">%</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="hash-tab2" data-bs-toggle="tab" data-bs-target="#hash2"
                            type="button" role="tab" aria-controls="hash" aria-selected="false">#</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tab-content" id="myTab2Content">
                  <div class="tab-pane fade show active" id="percent2" role="tabpanel" aria-labelledby="percent-tab2">
                    <div class="registrant cust_scroll">
                      <div v-for="(acr, inx) in activity_region"
                        class="d-flex justify-content-between registrant_section i_mini" :key="inx">
                        <div class="name">
                          <h3>
                            <span v-if="inx==0" class="circle blue"></span>
                            <span v-if="inx==1" class="circle pink"></span>
                            <span v-if="inx==2" class="circle orange"></span>
                            <span v-if="inx==3" class="circle skyblue"></span>
                            <span v-if="inx==4" class="circle green"></span>
                            {{acr.name}}
                          </h3>
                        </div>
                        <div class="percent">
                          <h3>{{acr.percent}}%</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="hash2" role="tabpanel" aria-labelledby="hash-tab2">
                    <div class="registrant cust_scroll">
                      <div v-for="(acr, inx) in activity_region"
                        class="d-flex justify-content-between registrant_section i_mini" :key="inx">
                        <div class="name">
                          <h3>
                            <span v-if="inx==0" class="circle blue"></span>
                            <span v-if="inx==1" class="circle pink"></span>
                            <span v-if="inx==2" class="circle orange"></span>
                            <span v-if="inx==3" class="circle skyblue"></span>
                            <span v-if="inx==4" class="circle green"></span>
                            {{acr.name}}
                          </h3>
                        </div>
                        <div class="percent">
                          <h3>{{acr.value}}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12 text-center">
                <div class="align-items-start d-flex w-100 withprogress white_card border_radius_20 mt-3">
                  <div class="detailsProgress justify-content-between w-100">
                    <div class="align-items-center d-flex d-xxl-flex justify-content-between w-100 h_150">
                      <h2 class="no_data">No Data Found</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div class="self_report graphs-2 mt-0">
            <div class="row">
              <div class="col-sm-6 col-xl-6 top_mob mt-5" ref="SelfReportedComfortLevelDiv">
                <div class="white_card h-100 border-radius-20">
                  <div class="activity_region border-bottom tab_report p-3 mb_18 pb_14 aft_full_wdt">
                    <div class="row">
                      <div class="col-10 col-sm-9 align-self-center">
                        <h2 class="mb_20"> Reason for Assessment</h2>
                      </div>
                      <div v-if="hideComfortLevelDownloadBtn" class="col-2 col-sm-3 align-self-center text-end">
                        <div class="m-auto" data-html2canvas-ignore="true">
                          <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('SelfReportedComfortLevelDiv', 'Reason for Assessment', '', '', '', self_comfort_levels)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                              <g data-name="Group 12689">
                                <path data-name="Path 114"
                                  d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                  transform="translate(-3 -3)"
                                  style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                              </g>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content px-3" id="myTabContent">
                    <div class="tab-pane fade show active" id="Unique" role="tabpanel" aria-labelledby="Unique-tab">
                      <div class="activity_region mb_10 ipad_visited">
                        <div class="row">
                          <div class="col-sm-12 align-self-center text-lg-end">
                            <div class="d-flex justify-content-end  align-items-baseline">
                              <div class="d-none visited_pages ipad_visited">
                                <div class="heading">
                                  <div class="form-group mb-0 by_min">
                                    <v-select @input="filterComfortLevel" :reduce="(region_list) => region_list.id"
                                      :options="region_list" label="name" placeholder="By Region" name="Region"
                                      v-model="listConfig.comfortLevel_regionBy" v-validate="'required'" browserAutocomplete="false" autocomplete="off" />
                                    <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                                      {{ errors.first("addUserForm.Region") }}
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div class="d-none dropdown calender_dropdown">
                                <a href="javascript:void(0)" id="dropdownMenuLink3" data-bs-toggle="dropdown"
                                  aria-expanded="false" class="ml_20 dropdown-toggle" role="button"
                                  data-bs-auto-close="false">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)"
                                      style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                  </svg>
                                </a>
                                <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                                  aria-labelledby="dropdownMenuLink3">
                                  <div class="date pl_6">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <div class="heading_5 font_bold mb_10">From</div>
                                      <a href="javascript:void(0)" class="reset_btn" @click="resetDatesComfortLevel">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
                                          viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000"
                                          xml:space="preserve">
                                          <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                          <g>
                                            <path
                                              d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                    <div class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                      <span class="mr_10">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          viewBox="0 0 16 16">
                                          <path data-name="Path 130"
                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                            transform="translate(-2 -2)"
                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                        </svg>
                                      </span>
                                      <datepicker placeholder="MM DD, YYYY" v-model="listConfig.comfortLevel_sdate"
                                        :format="customFormatter" @selected="changeStartDateComfortLevel"
                                        :disabledDates="disabledFutureDates"></datepicker>
                                      <p class="min-date-value d-none">
                                        {{ listConfig.comfortLevel_sdate | formatDate }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="date pl_6 mt_20">
                                    <div class="heading_5 font_bold mb_10">To</div>
                                    <div class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                      <span class="mr_10">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          viewBox="0 0 16 16">
                                          <path data-name="Path 130"
                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                            transform="translate(-2 -2)"
                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                        </svg>
                                      </span>
                                      <datepicker placeholder="MM DD, YYYY" v-model="listConfig.comfortLevel_edate"
                                        :format="customFormatter" @selected="changeEndDateComfortLevel"
                                        :disabled="isDisabledComfortLevel" :disabledDates="disabledDates"></datepicker>
                                      <p class="max-date-value d-none">
                                        {{ listConfig.comfortLevel_edate | formatDate }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="button mt_30">
                                    <a href="javascript:" @click="updateComfortLevel"
                                      class="btn btn_primary px_14">Update</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="self_comfort_levels.length"  class="bar_graph mb_34">
                        <bar-chart :chart-data="selfcomfortdatacollection" ref="appendSelfComfort"></bar-chart>
                      </div>
                       <div v-else class="bar_graph mb_34">
                        <h2 class="no_data">No Data Found</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-6 mt-5" ref="WorkAreaAssessedDiv">
                <div class="white_card h-100 border-radius-20">
                  <div class="activity_region border-bottom tab_report p-3 mb_18 pb_14 aft_full_wdt">
                    <div class="row">
                      <div class="col-8 col-sm-8 align-self-center">
                        <h2 class="mb_20">Work Area Assessed</h2>
                      </div>
                      <div v-if="hideWorkAreaDownloadBtn" class="col-4 col-sm-4 align-self-center text-end">
                        <div class="align-items-center " data-html2canvas-ignore="true">
                          <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('WorkAreaAssessedDiv', 'Work-Area-Assessed', '', '', '', work_area_assessed)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                              <g data-name="Group 12689">
                                <path data-name="Path 114"
                                  d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                  transform="translate(-3 -3)"
                                  style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                              </g>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content px-3" id="myTabContent">
                    <div class="tab-pane fade show active" id="Unique" role="tabpanel" aria-labelledby="Unique-tab">
                      <div class="activity_region mb_10 ipad_visited">
                        <div class="row">
                          <div class="col-sm-12 align-self-center text-lg-end">
                            <div class="d-flex justify-content-end align-items-baseline">
                              <div class="d-none visited_pages ipad_visited">
                                <div class="heading">
                                  <div class="form-group mb-0 by_min">
                                    <v-select @input="filterWorkArea" :reduce="(region_list) => region_list.id"
                                      :options="region_list" label="name" placeholder="By Region" name="Region"
                                      v-model="listConfig.workArea_regionBy" v-validate="'required'" browserAutocomplete="false" autocomplete="off"/>
                                    <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                                      {{ errors.first("addUserForm.Region") }}
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div class="d-none dropdown calender_dropdown align-self-center">
                                <a href="javascript:void(0)" id="dropdownMenuLink4" data-bs-toggle="dropdown"
                                  aria-expanded="false" class="ml_20 dropdown-toggle" role="button"
                                  data-bs-auto-close="false">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)"
                                      style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                  </svg>
                                </a>
                                <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                                  aria-labelledby="dropdownMenuLink4">
                                  <div class="date pl_6">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <div class="heading_5 font_bold mb_10">From</div>
                                      <a href="javascript:void(0)" class="reset_btn" @click="resetDatesWorkArea">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
                                          viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000"
                                          xml:space="preserve">
                                          <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                          <g>
                                            <path
                                              d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                    <div class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                      <span class="mr_10">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          viewBox="0 0 16 16">
                                          <path data-name="Path 130"
                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                            transform="translate(-2 -2)"
                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                        </svg>
                                      </span>
                                      <datepicker placeholder="MM DD, YYYY" v-model="listConfig.workArea_sdate"
                                        :format="customFormatter" @selected="changeStartDateWorkArea"
                                        :disabledDates="disabledFutureDates"></datepicker>
                                      <p class="min-date-value d-none">
                                        {{ listConfig.workArea_sdate | formatDate }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="date pl_6 mt_20">
                                    <div class="heading_5 font_bold mb_10">To</div>
                                    <div class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                      <span class="mr_10">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          viewBox="0 0 16 16">
                                          <path data-name="Path 130"
                                            d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                            transform="translate(-2 -2)"
                                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                        </svg>
                                      </span>
                                      <datepicker placeholder="MM DD, YYYY" v-model="listConfig.workArea_edate"
                                        :format="customFormatter" @selected="changeEndDateWorkArea"
                                        :disabled="isDisabledWorkArea" :disabledDates="disabledDates"></datepicker>
                                      <p class="max-date-value d-none">
                                        {{ listConfig.workArea_edate | formatDate }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="button mt_30">
                                    <a href="javascript:" @click="updateWorkArea"
                                      class="btn btn_primary px_14">Update</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="work_area_assessed.length" class="stadn_graph mb_34">
                        <bar-chart :chart-data="workareadatacollection" ref="appendWorkArea"></bar-chart>
                      </div>
                      <div v-else class="stadn_graph mb_34">
                        <h2 class="no_data">No Data Found</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade box_up download_modal" id="download" tabindex="-1" aria-labelledby="downloadLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="downloadLabel">
                <h3 class="heading_2">Download</h3>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-around">
                <div class="col-md-3">
                  <div class="image_upload text-center downloadDocumentClass disabled">
                    <a href="javascript:" class="text-center" @click="enableDownloadBtn('document')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="42" viewBox="0 0 36 42" class="d-block">
                        <path data-name="Path 122" d="M13.889 23h13.333m-13.333 8.889h13.333M31.667 43H9.444A4.444 4.444 0 0 1 5 38.556V7.444A4.444 4.444 0 0 1 9.444 3h12.414a2.222 2.222 0 0 1 1.571.651L35.46 15.682a2.222 2.222 0 0 1 .651 1.571v21.3A4.444 4.444 0 0 1 31.667 43z" transform="translate(-4 -2)" style=" fill:none; stroke-linecap:round; stroke-linejoin:round; stroke-width:2px " />
                      </svg>
                      Download as a document
                    </a>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="image_upload text-center downloadImageClass">
                    <a href="javascript:" class="text-center" @click="enableDownloadBtn('image')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="38.891" height="38.063" viewBox="0 0 38.891 38.063" class="d-block">
                        <path data-name="Path 143" d="m4 31.047 10.336-10.336a4.508 4.508 0 0 1 6.374 0l10.337 10.336m-4.508-4.508 3.575-3.575a4.508 4.508 0 0 1 6.374 0l3.575 3.575M26.539 13.016h.023M8.508 40.063h27.047a4.508 4.508 0 0 0 4.508-4.508V8.508A4.508 4.508 0 0 0 35.555 4H8.508A4.508 4.508 0 0 0 4 8.508v27.047a4.508 4.508 0 0 0 4.508 4.508z" transform="translate(-2.586 -3)" style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                      </svg>
                      Download as an image
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-top-0 mx-auto mb_50">
              <button type="button" class="btn btn_primary_light mr_30" data-bs-dismiss="modal"> Close </button>
              <button type="button" class="btn btn_primary sml_download" @click="downloadChart()">Download</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import $ from "jquery";
  import moment from "moment";
  import "vue-select/dist/vue-select.css";
  import Datepicker from "vuejs-datepicker";
  import PieChart from "../../components/charts/PieChart.js";
  import BarChart from "../../components/charts/BarChart.js";
  import LineChart from "../../components/charts/LineChart.js";
  import DoughnutChart from "../../components/charts/DoughnutChart.js";
  // import jsPDF from 'jspdf';
  import html2Canvas from 'html2canvas';
  // import Spinner from "../../components/Spinner.vue";
  import commonFunction from "../../mixin/commonFunction";
  import { utils, writeFileXLSX } from 'xlsx';

  export default {
    name: "my_reports",
    components: {
      Datepicker,
      PieChart,
      BarChart,
      LineChart,
      DoughnutChart,
      // Spinner
    },
    mixins: [commonFunction],
    data() {
      return {
        showMostViewedContent:false,
        show: false,
        doughnut_chart_options:  {
          tooltips: {enabled: false},
          hover: {mode: null},
        },
        chart_options:  {
          legend: { display: false },
          cornerRadius: 7,
        },
        all_time_chart_options:  {
         legend: { 
            display: true,
            labels: {
              boxWidth: 3, 
              padding:22
            },
          }, 
          cornerRadius: 7,
        },
        mitisitedatacollection: {
          datasets: [{
            data: [0,100],
            backgroundColor: ['#4880FF', '#4880FF4D'],
          }]
        },
        mitiselfdatacollection: {
          datasets: [{
            data: [0,100],
            backgroundColor: ['#4880FF', '#4880FF4D'],
          }]
        },
        siteUniqueCollection: {
          labels: [],
          datasets: [{
            data: [],
            label: "Current Site Registrants",
            borderColor: "#4880FF",
            fill: false,
          }, ],
        },
        siteRevisitsCollection: {
          labels: [],
          datasets: [{
            data: [],
            label: "YTD Site Registrants",
            borderColor: "#29CB96",
            fill: false,
          }, ],
        },
        allTimeSiteRegistrantsCollection: {
          labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
          datasets:[],
        },
        piechartdatacollection: {
          labels: [],
          datasets: [{
            backgroundColor: ['#6665DD', '#FF6A81', '#F2A149', '#4880FF', '#29CB96'],
            data: []
          }]
        },
        selfcomfortdatacollection: {
          labels: [],
          datasets: [{
            data: [],
            label: "Reason for Assessment",
            backgroundColor: ["#29CB96", "#FFC9AE", "#4880FF", "#93E4CA", ],
            maxBarThickness: 40,
          }, ],
        },
        workareadatacollection: {
          labels: [],
          datasets: [{
            data: [],
            label: "Work Area Assessed",
            backgroundColor: [
              "#4880FF",
              "#93E4CA",
              "#FFC9AE",
              "#FDDF7F",
              "#F3C695",
            ],
            maxBarThickness: 40,
          }, ],
        },
        region_list: [{
          id: 'ALL',
          name: "ALL"
        }],
        disabledDates: {
          to: new Date(Date.now() - 8640000),
        },
        disabledFutureDates: {
          from: new Date(Date.now()),
        },
        listConfig: {
          typeOfAssessment: '',
          //Main filter...
          start_date: null,
          end_date: null,
          regionBy: null,
          //Site Registrants filter...
          site_sdate: null,
          site_edate: null,
          site_regionBy: null,
          //Activity Region filter...
          region_sdate: null,
          region_edate: null,
          region_regionBy: null,
          //Self-Reported Comfort Levels filter...
          comfortLevel_sdate: null,
          comfortLevel_edate: null,
          comfortLevel_regionBy: null,
          //Work Area Assessed filter...
          workArea_sdate: null,
          workArea_edate: null,
          workArea_regionBy: null,
        },
        data_length: 0,

        selected: '',
        isDisabled: '',
        isDisabledSite: '',
        isDisabledRegion: '',
        isDisabledComfortLevel: '',
        isDisabledWorkArea: '',

        completed_self_assessments: 0,
        complete_evaluations: 0,
        evaluations_requested: 0,
        RegistrantsUnique: false,
        RegistrantsRevisits: false,
        site_registrants_unique: 0,
        site_registrants_revisits: 0,
        mitigation_site_cost: 0,
        mitigation_site_count: 0,
        mitigation_site_percent: 0,
        mitigation_selfAssessment_cost: 0,
        mitigation_selfAssessment_count: 0,
        mitigation_selfAssessment_percent: 0,
        mitigation_cost_saving: 0,
        hideSiteRegistrantsDownloadBtn: true,
        site_registrantsUnique: [],
        site_registrantsRevisits: [],
        site_registrantsUniqueCSV: [],
        site_registrantsRevisitsCSV: [],
        activity_region: [],
        hideActivityRegionDownloadBtn: true,
        activity_region_registrantsCSV: [],
        activity_region_EECSV: [],
        activity_region_SACSV: [],
        hideComfortLevelDownloadBtn: true,
        self_comfort_levels: [],
        self_comfort_levels_ComCSV: [],
        self_comfort_levels_DisCSV: [],
        work_area_assessed_HomeCSV: [],
        work_area_assessed_LabCSV: [],
        work_area_assessed_OfficeCSV: [],
        work_area_assessed_AnywhereCSV: [],
        work_area_assessed: [],
        hideWorkAreaDownloadBtn: true,
        most_visited_pages: [],
        downloadRef: '',
        downloadType: 'image',
        downloadImageName: '',
        downloadMaxHeight: '',
        downloadMinHeight: '',
        downloadHeightClass: '',
        downloadCSVData: '',
        rowsData: [],
        urlClientId: null,
        allTimeRegistrants: true,
        allTimeSiteRegistrant_count: 0,
        allTimeSiteRegistrant: [],
        allTimeSiteRegistrant_csv: [],
        months : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        previousStartDate: null,
        yearColorMap : {
                  "2022": "#20c997",
                  "2023": "#6c757d",
                  "2024": "#ff9500",
                  "2025": "#198754",
                  "2026": "#6f42c1"
              },
      };
    },
    computed: {
      btnText: function () {
        if (this.show) {
          return "";
        }
        return "";
      },
    },
    destroyed() {
      this.$parent.employeeDashboard = false
    },
    mounted() {
      document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
      this.$parent.employeeDashboard = true;
      // Set client id from url...
      if(this.$route.params.id != '' && this.$route.params.id != undefined) {
        this.urlClientId = this.$route.params.id;
      }
      //Get all regions from Client data...
      var clientData = this.$store.getters["auth/authUser"];
      var clientRegions = clientData.client.client_regions.replace(/[^a-zA-Z,/ ]/g, "");
      var clientRegionsArr = clientRegions.split(",");
      for (let i = 0; i < clientRegionsArr.length; i++) {
        this.region_list.push({"id": clientRegionsArr[i], "name": clientRegionsArr[i]});
      }
      // this.mostVisitedPages();
      this.getDashboardData();
      this.getSiteRegistrant();
      this.getSelfComfortLevels();
      this.getWorkAreaAssessed();
    },
    methods: {
      prepareChart(ref, imageName, maxHeight, minHeight, heightClass, csvData) {
        this.downloadRef = ref;
        this.downloadImageName = imageName;
        this.downloadMaxHeight = maxHeight;
        this.downloadMinHeight = minHeight;
        this.downloadHeightClass = heightClass;
        this.downloadCSVData = csvData;
        this.enableDownloadBtn('image');
      },
      enableDownloadBtn(btnType) {
        if(btnType == 'image') {
          $('.downloadImageClass').removeClass('disabled');
          $('.downloadDocumentClass').addClass('disabled');
        }
        else if(btnType == 'document') {
          $('.downloadDocumentClass').removeClass('disabled');
          $('.downloadImageClass').addClass('disabled');
        }
        this.downloadType = btnType;
      },
      async downloadChart() {
        var _this = this;
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        
        //Audit log entry   
        var sectionName = '';  
        switch(_this.downloadRef) {
          case 'SiteRegistrantsDiv':
            sectionName = 'Site Registrants';
            break;
          case 'TotalActivityByRegionDiv':
            sectionName = 'Total Activity';
            break;
          case 'SelfReportedComfortLevelDiv':
            sectionName = 'Reason for Assessment';
            break;
         // case 'AllTimeSiteRegistrantsDiv':
           // sectionName = 'All Time Site-RegistrantsDiv';
           // break;
          default:
            sectionName = 'Work Area Assessed';
        } 
        var requestData = {'section': sectionName,'dashboard':'Program Overview'};
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/audit-log-for-download",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: this.encodeAPIRequestWithImages(requestData),
        };
        this.axios(config)
        .then(({ data }) => {
          console.log(data.message);
        }).catch(({ response }) => {
          if (response.status == 401) {
            _this.tokenExpired();
          }
        });
        
        if(_this.downloadHeightClass != '') {
          $('.cust_scroll').css({'overflow': 'unset'});
          $('.'+_this.downloadHeightClass).css({'height': _this.downloadMaxHeight});
        }
        $('.white_card').css({'box-shadow': 'unset'});
        const el =  _this.$refs[_this.downloadRef];
        const options = {
            type: "dataURL",
            scrollX: 0,
            scrollY: -window.scrollY
        };
        const printCanvas = await html2Canvas(el, options);
        setTimeout(() => {
          const link = document.createElement("a");
          if(_this.downloadType == 'image'){
              link.setAttribute("download", _this.downloadImageName + '.png');
              link.setAttribute(
                  "href",
                  printCanvas
                  .toDataURL("image/png")
                  .replace("image/png", "image/octet-stream")
              );
            link.click();
          }
          else if(_this.downloadType == 'document')
          {
              var xlsxSheetData1 = ''; var xlsxSheetName1 = '';
              var xlsxSheetData2 = ''; var xlsxSheetName2 = '';
              var xlsxSheetData3 = ''; var xlsxSheetName3 = '';
              var xlsxSheetData4 = ''; var xlsxSheetName4 = '';
              if(_this.downloadRef == 'SiteRegistrantsDiv')
              {
                if(_this.site_registrantsUniqueCSV.length) {
                  xlsxSheetName2 = 'Current Site Registrants';
                  xlsxSheetData2 = utils.json_to_sheet(_this.site_registrantsUniqueCSV);
                }
                if(_this.site_registrantsRevisitsCSV.length) {
                  xlsxSheetName3 = 'YTD Site Registrants';
                  xlsxSheetData3 = utils.json_to_sheet(_this.site_registrantsRevisitsCSV);
                }
                if(_this.allTimeSiteRegistrant_csv.length) {
                  xlsxSheetName1 = 'All Time Site-Registrants';
                  xlsxSheetData1 = utils.json_to_sheet(_this.allTimeSiteRegistrant_csv);
                }
              }
              else if(_this.downloadRef == 'TotalActivityByRegionDiv')
              {
                if(_this.activity_region_registrantsCSV.length) {
                  xlsxSheetName1 = 'Registrants';
                  xlsxSheetData1 = utils.json_to_sheet(_this.activity_region_registrantsCSV);
                }
                if(_this.activity_region_EECSV.length) {
                  xlsxSheetName2 = 'Ergo Evals';
                  xlsxSheetData2 = utils.json_to_sheet(_this.activity_region_EECSV);
                }
                if(_this.activity_region_SACSV.length) {
                  xlsxSheetName3 = 'Self-Assessments';
                  xlsxSheetData3 = utils.json_to_sheet(_this.activity_region_SACSV);
                }
              }
              else if(_this.downloadRef == 'SelfReportedComfortLevelDiv')
              {
                if(_this.self_comfort_levels_ComCSV.length) {
                  xlsxSheetName1 = 'Reason for Assessment';
                  xlsxSheetData1 = utils.json_to_sheet(_this.self_comfort_levels_ComCSV);
                }
                if(_this.self_comfort_levels_DisCSV.length) {
                  xlsxSheetName2 = 'Self-Reported Discomfort Levels';
                  xlsxSheetData2 = utils.json_to_sheet(_this.self_comfort_levels_DisCSV);
                }
              }
              else if(_this.downloadRef == 'WorkAreaAssessedDiv')
              {
                if(_this.work_area_assessed_HomeCSV.length) {
                  xlsxSheetName1 = 'Work Area Assessed Home';
                  xlsxSheetData1 = utils.json_to_sheet(_this.work_area_assessed_HomeCSV);
                }
                if(_this.work_area_assessed_LabCSV.length) {
                  xlsxSheetName2 = 'Work Area Assessed Lab';
                  xlsxSheetData2 = utils.json_to_sheet(_this.work_area_assessed_LabCSV);
                }
                if(_this.work_area_assessed_OfficeCSV.length) {
                  xlsxSheetName3 = 'Work Area Assessed Office';
                  xlsxSheetData3 = utils.json_to_sheet(_this.work_area_assessed_OfficeCSV);
                }
                if(_this.work_area_assessed_AnywhereCSV.length) {
                  xlsxSheetName4 = 'Work Area Assessed Anywhere';
                  xlsxSheetData4 = utils.json_to_sheet(_this.work_area_assessed_AnywhereCSV);
                }
              }
              // A workbook is the name given to an Excel file
              var wb = utils.book_new(); // make Workbook of Excel
              // add Worksheet to Workbook // Workbook contains one or more worksheets
              if(xlsxSheetData1) {
                utils.book_append_sheet(wb, xlsxSheetData1, xlsxSheetName1);
              }
              if(xlsxSheetData2) {
                utils.book_append_sheet(wb, xlsxSheetData2, xlsxSheetName2);
              }
              if(xlsxSheetData3) {
                utils.book_append_sheet(wb, xlsxSheetData3, xlsxSheetName3);
              }
              if(xlsxSheetData4) {
                utils.book_append_sheet(wb, xlsxSheetData4, xlsxSheetName4);
              }
              // export Excel file
              if(wb.SheetNames.length) {
                writeFileXLSX(wb, _this.downloadImageName + '.xlsx') // name of the file is 'book.xlsx'
              }
              ///////////// Commented PDF Code //////////////
              // //Adjust width and height...
              // var topMargin = 20;
              // var leftMargin = 10;
              // var imgWidth =  (printCanvas.width * 60) / 240;
              // var imgHeight = (printCanvas.height * 60) / 240;
              // if(imgWidth < 100){
              //   leftMargin = 50;
              // }
              // else if(imgWidth < 130 && imgWidth > 100){
              //   leftMargin = 30;
              // }
              // else if(imgWidth < 160 && imgWidth > 130){
              //   leftMargin = 20;
              // }
              // //jsPDF changes...
              // var pdf = new jsPDF('p', 'mm', 'a4');
              // pdf.addImage(printCanvas, 'png', leftMargin, topMargin, imgWidth, imgHeight); // 2: 19
              // pdf.save(_this.downloadImageName+`${$('.pdf-title').text()}.pdf`);
          }
          if(_this.downloadHeightClass != '') {
            $('.cust_scroll').css({'overflow': 'auto'});
            $('.'+_this.downloadHeightClass).css({'height': _this.downloadMinHeight});
          }
          $('.white_card').css({'box-shadow': '5px 10px 20px #f198361a'});
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          document.getElementsByClassName("btn-close")[0].click();
        }, 100);
      },
      exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename);
        }
        else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      updateSiteRegistrants() {
        document.getElementById("dropdownMenuLink1").click();
        this.filterSiteRegistrants();
      },
      updateActivityRegion() {
        // document.getElementById("dropdownMenuLink2").click();
        // this.filterActivityRegion();
      },
      updateComfortLevel() {
        document.getElementById("dropdownMenuLink3").click();
        this.filterComfortLevel();
      },
      updateWorkArea() {
        document.getElementById("dropdownMenuLink4").click();
        this.filterWorkArea();
      },
      showAllTimeRegistrants(){
        this.allTimeRegistrants = true;
        this.RegistrantsUnique = false;
        this.RegistrantsRevisits = false;
      },
      showRegistrantsUnique(){
        this.RegistrantsUnique = true;
        this.allTimeRegistrants = false;
        this.RegistrantsRevisits = false;
      },
      showRegistrantsRevisits(){
        this.RegistrantsRevisits = true;
        this.allTimeRegistrants = false;
        this.RegistrantsUnique = false;
      },
      downloadSiteRegistrantsBtn(siteData) {
        if(siteData.length == 0) {
          this.hideSiteRegistrantsDownloadBtn = false;
        }
        else {
          this.hideSiteRegistrantsDownloadBtn = true;
        }
      },
      resetDatesMain() {
          this.listConfig.start_date = "";
          this.listConfig.end_date = "";
          this.listConfig.regionBy = "";
          this.listConfig.site_sdate = "";
          this.listConfig.site_edate = "";
          this.listConfig.site_regionBy = "";
          this.listConfig.region_sdate = "";
          this.listConfig.region_edate = "";
          this.listConfig.region_regionBy = "";
          this.listConfig.comfortLevel_sdate = "";
          this.listConfig.comfortLevel_edate = "";
          this.listConfig.comfortLevel_regionBy = "";
          this.listConfig.workArea_sdate = "";
          this.listConfig.workArea_edate = "";
          this.listConfig.workArea_regionBy = "";
          this.isDisabled = true;
          this.isDisabledSite = true;
          this.isDisabledRegion = true;
          this.isDisabledComfortLevel = true;
          this.isDisabledWorkArea = true;

          this.getDashboardData();
          // this.mostVisitedPages();
          this.getSiteRegistrant();
          this.getSelfComfortLevels();
          this.getWorkAreaAssessed();
      },
      resetDatesSite() {
        this.listConfig.site_sdate = "";
        this.listConfig.site_edate = "";
        this.listConfig.site_regionBy = "";
        this.isDisabledSite = true;
        this.updateSiteRegistrants();
      },
      resetDatesRegion() {
        this.listConfig.region_sdate = "";
        this.listConfig.region_edate = "";
        this.listConfig.region_regionBy = "";
        this.isDisabledRegion = true;
        this.updateActivityRegion();
      },
      resetDatesComfortLevel() {
        this.listConfig.comfortLevel_sdate = "";
        this.listConfig.comfortLevel_edate = "";
        this.listConfig.comfortLevel_regionBy = "";
        this.isDisabledComfortLevel = true;
        this.updateComfortLevel();
      },
      resetDatesWorkArea() {
        this.listConfig.workArea_sdate = "";
        this.listConfig.workArea_edate = "";
        this.listConfig.workArea_regionBy = "";
        this.isDisabledWorkArea = true;
        this.updateWorkArea();
      },
      // mostVisitedPages() {
      //   let config = {
      //     method: "post",
      //     url: process.env.VUE_APP_API_URL + "/api/most-visited-pages",
      //     headers: {
      //       Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      //     },
      //     data: {request_data : this.encodeAPIRequestWithImages(this.listConfig)},
      //   };
      //   this.$refs.Spinner.modalLoader = true;
      //   this.showMostViewedContent = false;
      //   this.axios(config)
      //     .then(({ data }) => {
      //       if (data.success) {
      //         var decodedJson = this.decodeAPIResponse(data.data);
      //         this.most_visited_pages = decodedJson.most_visited_pages;
      //         this.$refs.Spinner.modalLoader = false;
      //         this.showMostViewedContent = true;
      //       }
      //       else {
      //         this.most_visited_pages = [];
      //         this.$refs.Spinner.modalLoader = false;
      //       }
      //     })
      //     .catch(() => {
      //       // 
      //     });
      // },
      getDashboardData() {
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        var _this = this;
        var accessURL = process.env.VUE_APP_API_URL + "/api/program-overview";
        if(this.urlClientId) {
          accessURL = process.env.VUE_APP_API_URL + "/api/program-overview/" + this.urlClientId;
        }
        let config = {
          method: "get",
          url: accessURL,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
        };
        _this.axios(config)
          .then(({ data }) => {
            if (data.success) {
               var decodedJson = _this.decodeAPIResponse(data.records.data);
              _this.completed_self_assessments = decodedJson.completed_self_assessments;
              _this.complete_evaluations = decodedJson.complete_evaluations;
              _this.evaluations_requested = decodedJson.evaluations_requested;
              //Mitigation & Cost Savings...
              _this.mitigation_site_cost = decodedJson.mitigation_site_cost;
              _this.mitigation_site_count = decodedJson.mitigation_site_count;
              _this.mitigation_site_percent = decodedJson.mitigation_site_percent;
              _this.mitigation_selfAssessment_cost = decodedJson.mitigation_selfAssessment_cost;
              _this.mitigation_selfAssessment_count = decodedJson.mitigation_selfAssessment_count;
              _this.mitigation_selfAssessment_percent = decodedJson.mitigation_selfAssessment_percent;
              _this.mitigation_cost_saving = decodedJson.mitigation_cost_saving;
              _this.mitisitedatacollection.datasets[0].data = [];
              _this.mitisitedatacollection.datasets[0].data.push(_this.mitigation_site_percent);
              _this.mitisitedatacollection.datasets[0].data.push((100 - _this.mitigation_site_percent));
              _this.mitiselfdatacollection.datasets[0].data = [];
              _this.mitiselfdatacollection.datasets[0].data.push(_this.mitigation_selfAssessment_percent);
              _this.mitiselfdatacollection.datasets[0].data.push((100 - _this.mitigation_selfAssessment_percent));
             
              setTimeout(() => {
                //Mitigation & Cost Savings Site Eval...
                _this.$refs.appendMitiSite.renderChart(
                  _this.mitisitedatacollection,_this.doughnut_chart_options
                );
                //Mitigation & Cost Savings Self-Assessment...
                _this.$refs.appendMitiSelf.renderChart(
                  _this.mitiselfdatacollection,_this.doughnut_chart_options
                );
              }, 100);
            }
            else {
              _this.completed_self_assessments = 0;
              _this.complete_evaluations = 0;
              _this.evaluations_requested = 0;
              //Mitigation & Cost Savings...
              _this.mitigation_site_cost = 0;
              _this.mitigation_site_count = 0;
              _this.mitigation_site_percent = 0;
              _this.mitigation_selfAssessment_cost = 0;
              _this.mitigation_selfAssessment_count = 0;
              _this.mitigation_selfAssessment_percent = 0;
              _this.mitigation_cost_saving = 0;
              _this.mitisitedatacollection.datasets[0].data = [0,100];
              _this.mitiselfdatacollection.datasets[0].data = [0,100];
             
              setTimeout(() => {
                // this.downloadSiteRegistrantsBtn(_this.site_registrantsRevisits);
                _this.hideActivityRegionDownloadBtn = false;
                _this.hideComfortLevelDownloadBtn = false;
                _this.hideWorkAreaDownloadBtn = false;
                //Mitigation & Cost Savings Site Eval...
                _this.$refs.appendMitiSite.renderChart(
                  _this.mitisitedatacollection,_this.doughnut_chart_options
                );
                //Mitigation & Cost Savings Self-Assessment...
                _this.$refs.appendMitiSelf.renderChart(
                  _this.mitiselfdatacollection,_this.doughnut_chart_options
                );
              }, 100);
            }
            // this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            if (response.status == 404) {
              _this.$router.push({ name: "404" });
            }
            else if (response.status == 403) {
              _this.$router.push({ name: "403" });
            }
            else if (response.status == 401) {
              _this.tokenExpired();
            }
          });
      },
      getSiteRegistrant() {
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        var _this = this;
        var accessURL = process.env.VUE_APP_API_URL + "/api/program-overview-site-registrant";
        let config = {
          method: "post",
          url: accessURL,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this.axios(config)
          .then(({ data }) => {
            if (data.success) {
               var decodedJson = _this.decodeAPIResponse(data.records.data);
              //Site Registrants...
              _this.site_registrants_unique = decodedJson.site_registrants_unique;
              _this.site_registrants_revisits = decodedJson.site_registrants_revisits;
              _this.allTimeSiteRegistrant_count = decodedJson.site_registrants_alltime_count;

              _this.siteUniqueCollection.labels = [];
              _this.siteUniqueCollection.datasets[0].data = [];
              _this.site_registrantsUniqueCSV = decodedJson.site_registrantsUniqueCSV;
              _this.site_registrantsUnique = decodedJson.site_registrantsUnique;
              _this.site_registrantsUnique.forEach(function (item) {
                _this.siteUniqueCollection.labels.push(item.name);
                _this.siteUniqueCollection.datasets[0].data.push(item.value);
              });

              _this.siteRevisitsCollection.labels = [];
              _this.siteRevisitsCollection.datasets[0].data = [];
              _this.site_registrantsRevisitsCSV = decodedJson.site_registrantsRevisitsCSV;
              _this.site_registrantsRevisits = decodedJson.site_registrantsRevisits;
              _this.site_registrantsRevisits.forEach(function (item) {
                _this.siteRevisitsCollection.labels.push(item.name);
                _this.siteRevisitsCollection.datasets[0].data.push(item.value);
              });
              
              // _this.allTimeSiteRegistrantsCollection.labels = [];
               _this.allTimeSiteRegistrantsCollection.datasets = [];
              
              _this.allTimeSiteRegistrant_csv = decodedJson.site_registrants_alltime_csv;
               _this.allTimeSiteRegistrant = decodedJson.site_registrants_alltime;

               _this.allTimeSiteRegistrant.forEach((item) => {
                  let borderColor;
                  if (Object.prototype.hasOwnProperty.call(_this.yearColorMap, item.name)) {
                      borderColor = _this.yearColorMap[item.name];
                  } else {
                      borderColor = _this.getRandomColor();
                  }

                  _this.allTimeSiteRegistrantsCollection.datasets.push({
                      label: item.name.toString(),
                      data: item.value.slice(0, 12),
                      borderColor: borderColor, // Use predefined color or random color
                      backgroundColor: 'transparent',
                  });
              });
              
             
              //Total Activity by Region...
              _this.piechartdatacollection.labels = [];
              _this.piechartdatacollection.datasets[0].data = [];
              _this.activity_region_registrantsCSV = decodedJson.activity_region_registrantsCSV;
              _this.activity_region_EECSV = decodedJson.activity_region_EECSV;
              _this.activity_region_SACSV = decodedJson.activity_region_SACSV;
              _this.activity_region = decodedJson.activity_region;
              _this.activity_region.forEach(function (item) {
                _this.piechartdatacollection.labels.push(item.name+' ('+item.percent+'%)');
                _this.piechartdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              });

              setTimeout(() => {
                // this.downloadSiteRegistrantsBtn(_this.site_registrantsRevisits);
                this.downloadSiteRegistrantsBtn(_this.allTimeSiteRegistrant);
                //Site Registrants...
                if(_this.site_registrantsUnique.length) {
                  _this.$refs.appendSiteUnique.renderChart(
                    _this.siteUniqueCollection,_this.chart_options
                  );
                }
                if(_this.site_registrantsRevisits.length) {
                  _this.$refs.appendSiteRevisits.renderChart(
                    _this.siteRevisitsCollection,_this.chart_options
                  );
                }
                
                if(_this.allTimeSiteRegistrant.length) {
                _this.$refs.allTimeSiteRef.renderChart(
                    _this.allTimeSiteRegistrantsCollection,_this.all_time_chart_options
                  );
                }

                //Total Activity by Region...
                if(_this.activity_region.length) {
                  _this.hideActivityRegionDownloadBtn = true;
                  _this.$refs.appendPieChart.renderChart(
                    _this.piechartdatacollection,_this.chart_options
                    );
                  }
                  else {
                    _this.hideActivityRegionDownloadBtn = false;
                  }


              }, 100);
            }
            else {
              //Site Registrants...
              _this.site_registrants_unique = 0;
              _this.site_registrants_revisits = 0;
              _this.site_registrantsUniqueCSV = [];
              _this.site_registrantsUnique = [];
              _this.siteUniqueCollection.labels = [];
              _this.siteUniqueCollection.datasets[0].data = [];
              _this.site_registrantsRevisitsCSV = [];
              _this.site_registrantsRevisits = [];
              _this.siteRevisitsCollection.labels = [];
              _this.siteRevisitsCollection.datasets[0].data = [];
              
              _this.allTimeSiteRegistrant = [];
              _this.allTimeSiteRegistrant_csv = [];
              _this.allTimeSiteRegistrantsCollection.labels = [];
              _this.allTimeSiteRegistrantsCollection.datasets = [];


                //Total Activity by Region...
              _this.activity_region_registrantsCSV = [];
              _this.activity_region_EECSV = [];
              _this.activity_region_SACSV = [];
              _this.activity_region = [];
              _this.piechartdatacollection.labels = [];
              _this.piechartdatacollection.datasets[0].data = [];


              setTimeout(() => {
                this.downloadSiteRegistrantsBtn(_this.allTimeSiteRegistrant);
                //Site Registrants...
                _this.$refs.appendSiteUnique.renderChart(
                  _this.siteUniqueCollection,_this.chart_options
                );
                _this.$refs.appendSiteRevisits.renderChart(
                  _this.siteRevisitsCollection,_this.chart_options
                );

                _this.$refs.allTimeSiteRef.renderChart(
                  _this.allTimeSiteRegistrantsCollection,_this.all_time_chart_options
                );

                 //Total Activity by Region...
                _this.$refs.appendPieChart.renderChart(
                  _this.piechartdatacollection,_this.chart_options
                );
              }, 100);
            }
            // this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            if (response.status == 404) {
              _this.$router.push({ name: "404" });
            }
            else if (response.status == 403) {
              _this.$router.push({ name: "403" });
            }
          });
      },
      getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
     },
      getSelfComfortLevels() {
        // return true;
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        var _this = this;
        var accessURL = process.env.VUE_APP_API_URL + "/api/program-overview-self-comfort-level";
        let config = {
          method: "post",
          url: accessURL,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this.axios(config)
          .then(({ data }) => {
            if (data.success) {
               var decodedJson = _this.decodeAPIResponse(data.records.data);
              //Self-Reported Comfort Levels...
              _this.selfcomfortdatacollection.labels = [];
              _this.selfcomfortdatacollection.datasets[0].data = [];
              _this.self_comfort_levels_ComCSV = decodedJson.self_comfort_levels_ComCSV;
              _this.self_comfort_levels_DisCSV = decodedJson.self_comfort_levels_DisCSV;
              _this.self_comfort_levels = decodedJson.self_comfort_levels;
              _this.self_comfort_levels.forEach(function (item) {
                // _this.selfcomfortdatacollection.labels.push(item.name + " (" + item.percent + "%)");
                _this.selfcomfortdatacollection.labels.push(item.name);
                _this.selfcomfortdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              });
              _this.selfcomfortdatacollection.datasets[0].data.push(0);

               setTimeout(() => {
                //Self-Reported Comfort Levels...
                  if(_this.self_comfort_levels.length) {
                    _this.hideComfortLevelDownloadBtn = true;
                    _this.$refs.appendSelfComfort.renderChart(
                      _this.selfcomfortdatacollection,_this.chart_options
                    );
                  }
                  else {
                    _this.hideComfortLevelDownloadBtn = false;
                  }
                }, 100);
            }
            else {
                //Self-Reported Comfort Levels...
              _this.self_comfort_levels_ComCSV = [];
              _this.self_comfort_levels_DisCSV = [];
              _this.self_comfort_levels = [];
              _this.selfcomfortdatacollection.labels = [];
              _this.selfcomfortdatacollection.datasets[0].data = [];

              setTimeout(() => {
                //Self-Reported Comfort Levels...
                _this.$refs.appendSelfComfort.renderChart(
                  _this.selfcomfortdatacollection,_this.chart_options
                );
              }, 100);
            }
            // this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            if (response.status == 404) {
              _this.$router.push({ name: "404" });
            }
            else if (response.status == 403) {
              _this.$router.push({ name: "403" });
            }
          });
      },
      getWorkAreaAssessed() {
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        var _this = this;
        var accessURL = process.env.VUE_APP_API_URL + "/api/program-overview-work-area-assessed";
        let config = {
          method: "post",
          url: accessURL,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this.axios(config)
          .then(({ data }) => {
            if (data.success) {
              var decodedJson = _this.decodeAPIResponse(data.records.data);
              //Work Area Assessed...
              _this.workareadatacollection.labels = [];
              _this.workareadatacollection.datasets[0].data = [];
              _this.work_area_assessed_HomeCSV = decodedJson.work_area_assessed_HomeCSV;
              _this.work_area_assessed_LabCSV = decodedJson.work_area_assessed_LabCSV;
              _this.work_area_assessed_OfficeCSV = decodedJson.work_area_assessed_OfficeCSV;
              _this.work_area_assessed_AnywhereCSV = decodedJson.work_area_assessed_AnywhereCSV;
              _this.work_area_assessed = decodedJson.work_area_assessed;
              _this.work_area_assessed.forEach(function (item) {
                _this.workareadatacollection.labels.push(item.name);
                _this.workareadatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              });
              _this.workareadatacollection.datasets[0].data.push(0);
              setTimeout(() => {
                //Work Area Assessed...
                if(_this.work_area_assessed.length) {
                  _this.hideWorkAreaDownloadBtn = true;
                  _this.$refs.appendWorkArea.renderChart(
                    _this.workareadatacollection,_this.chart_options
                  );
                }
                else {
                  _this.hideWorkAreaDownloadBtn = false;
                }
              }, 100);
            }
            else {
              //Work Area Assessed...
              _this.work_area_assessed_HomeCSV = [];
              _this.work_area_assessed_LabCSV = [];
              _this.work_area_assessed_OfficeCSV = [];
              _this.work_area_assessed_AnywhereCSV = [];
              _this.work_area_assessed = [];
              _this.workareadatacollection.labels = [];
              _this.workareadatacollection.datasets[0].data = [];
              setTimeout(() => {
                //Work Area Assessed...
                _this.$refs.appendWorkArea.renderChart(
                  _this.workareadatacollection,_this.chart_options
                );
              }, 100);
            }
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            if (response.status == 404) {
              _this.$router.push({ name: "404" });
            }
            else if (response.status == 403) {
              _this.$router.push({ name: "403" });
            }
          });
      },
      filterDataWithRegistrant(){
        this.filterMainData();
        this.getSiteRegistrant();
        this.getSelfComfortLevels();
        this.getWorkAreaAssessed();
      },
      filterMainData() {
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        var _this = this;
        _this.assignDefaultValues();
        var accessURL = process.env.VUE_APP_API_URL + "/api/program-overview-search";
        if(this.urlClientId) {
          accessURL = process.env.VUE_APP_API_URL + "/api/program-overview-search/" + this.urlClientId;
        }
        let config = {
          method: "post",
          url: accessURL,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this
          .axios(config)
          .then(({ data }) => {
            if (data.success) {
               var decodedJson = _this.decodeAPIResponse(data.records.data);
              _this.completed_self_assessments = decodedJson.completed_self_assessments;
              _this.complete_evaluations = decodedJson.complete_evaluations;
              _this.evaluations_requested = decodedJson.evaluations_requested;
              //Mitigation & Cost Savings...
              _this.mitigation_site_cost = decodedJson.mitigation_site_cost;
              _this.mitigation_site_count = decodedJson.mitigation_site_count;
              _this.mitigation_site_percent = decodedJson.mitigation_site_percent;
              _this.mitigation_selfAssessment_cost = decodedJson.mitigation_selfAssessment_cost;
              _this.mitigation_selfAssessment_count = decodedJson.mitigation_selfAssessment_count;
              _this.mitigation_selfAssessment_percent = decodedJson.mitigation_selfAssessment_percent;
              _this.mitigation_cost_saving = decodedJson.mitigation_cost_saving;
              _this.mitisitedatacollection.datasets[0].data = [];
              _this.mitisitedatacollection.datasets[0].data.push(_this.mitigation_site_percent);
              _this.mitisitedatacollection.datasets[0].data.push((100 - _this.mitigation_site_percent));
              _this.mitiselfdatacollection.datasets[0].data = [];
              _this.mitiselfdatacollection.datasets[0].data.push(_this.mitigation_selfAssessment_percent);
              _this.mitiselfdatacollection.datasets[0].data.push((100 - _this.mitigation_selfAssessment_percent));
             
              setTimeout(() => {
                //Mitigation & Cost Savings Site Eval...
                _this.$refs.appendMitiSite.renderChart(
                  _this.mitisitedatacollection,_this.doughnut_chart_options
                );
                //Mitigation & Cost Savings Self-Assessment...
                _this.$refs.appendMitiSelf.renderChart(
                  _this.mitiselfdatacollection,_this.doughnut_chart_options
                );
              }, 100);
              // this.mostVisitedPages();
            }
            else {
              _this.completed_self_assessments = 0;
              _this.complete_evaluations = 0;
              _this.evaluations_requested = 0;
              //Mitigation & Cost Savings...
              _this.mitigation_site_cost = 0;
              _this.mitigation_site_count = 0;
              _this.mitigation_site_percent = 0;
              _this.mitigation_selfAssessment_cost = 0;
              _this.mitigation_selfAssessment_count = 0;
              _this.mitigation_selfAssessment_percent = 0;
              _this.mitigation_cost_saving = 0;
              _this.mitisitedatacollection.datasets[0].data = [0,100];
              _this.mitiselfdatacollection.datasets[0].data = [0,100];
             
              setTimeout(() => {
                // this.downloadSiteRegistrantsBtn(_this.site_registrantsRevisits);
                _this.hideActivityRegionDownloadBtn = false;
                _this.hideComfortLevelDownloadBtn = false;
                _this.hideWorkAreaDownloadBtn = false;
                //Mitigation & Cost Savings Site Eval...
                _this.$refs.appendMitiSite.renderChart(
                  _this.mitisitedatacollection,_this.doughnut_chart_options
                );
                //Mitigation & Cost Savings Self-Assessment...
                _this.$refs.appendMitiSelf.renderChart(
                  _this.mitiselfdatacollection,_this.doughnut_chart_options
                );
              }, 100);
            }
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(( {response} ) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            if (response.status == 404) {
              _this.$router.push({ name: "404" });
            }
            else if (response.status == 403) {
              _this.$router.push({ name: "403" });
            }
            else if (response.status == 401) {
              _this.tokenExpired();
            }
          });
      },
      assignDefaultValues() {
        //Site Registrants...
        this.listConfig.site_sdate = this.listConfig.start_date;
        this.listConfig.site_edate = this.listConfig.end_date;
        this.listConfig.site_regionBy = this.listConfig.regionBy;
        //Total Activity by Region...
        this.listConfig.region_sdate = this.listConfig.start_date;
        this.listConfig.region_edate = this.listConfig.end_date;
        this.listConfig.region_regionBy = this.listConfig.regionBy;
        //Self-Reported Comfort Levels...
        this.listConfig.comfortLevel_sdate = this.listConfig.start_date;
        this.listConfig.comfortLevel_edate = this.listConfig.end_date;
        this.listConfig.comfortLevel_regionBy = this.listConfig.regionBy;
        //Work Area Assessed...
        this.listConfig.workArea_sdate = this.listConfig.start_date;
        this.listConfig.workArea_edate = this.listConfig.end_date;
        this.listConfig.workArea_regionBy = this.listConfig.regionBy;
        
        if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
          this.changeStartDateSite();
          this.changeStartDateRegion();
          this.changeStartDateComfortLevel();
          this.changeStartDateWorkArea();
        }
      },
      updateDatePicker(comeFrom) {
        // Extend dataTables search
        $.fn.dataTable.ext.search.push(function (settings, data) {
          var min = $(".min-date-value").html();
          var max = $(".max-date-value").html();
          var createdAt = data[0] || 0; // Our date column in the table
          if (
            min == "" ||
            max == "" ||
            (moment(createdAt).isSameOrAfter(min) &&
              moment(createdAt).isSameOrBefore(max))
          ) {
            return true;
          }
          return false;
        });
        if (comeFrom == "Main") {
          this.assignDefaultValues();
          // this.filterMainData();
          // this.getSiteRegistrant();
          this.filterDataWithRegistrant();
        }
      },
      // changeStartDate() {
      //   this.$nextTick(() => {
      //     this.isDisabled = false;
      //     this.disabledDates = {
      //       to: new Date(this.listConfig.start_date - 8640000),
      //       from: new Date(Date.now()),
      //     };
      //     if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
      //       this.updateDatePicker("Main");
      //     }
      //   });
      // },
      changeStartDate(newStartDate) {
        this.$nextTick(() => {
          const startDate = new Date(newStartDate);
          const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;
          
          if (endDate && startDate > endDate) {
            this.$toast.error('"From" date cannot be greater than the "To" date.', {
                position: "top-right",
                duration: 5000,
              });
            this.listConfig.start_date = this.previousStartDate;
          } else {
            this.previousStartDate = newStartDate;

            // Old changeStartDate code
            this.isDisabled = false;
            this.disabledDates = {
              to: new Date(this.listConfig.start_date - 8640000),
              from: new Date(Date.now()),
            };
            if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
              this.updateDatePicker("Main");
            }
          }
        });
      },
      changeEndDate() {
        this.$nextTick(() => {
          this.updateDatePicker("Main");
        });
      },
      changeStartDateSite() {
        this.$nextTick(() => {
          this.isDisabledSite = false;
          this.disabledDates = {
            to: new Date(this.listConfig.site_sdate - 8640000),
            from: new Date(Date.now()),
          };
          if (
            this.listConfig.site_edate != "" &&
            this.listConfig.site_edate != null
          ) {
            this.updateDatePicker('');
          }
        });
      },
      changeEndDateSite() {
        this.$nextTick(() => {
          this.updateDatePicker('');
        });
      },
      changeStartDateRegion() {
        this.$nextTick(() => {
          this.isDisabledRegion = false;
          this.disabledDates = {
            to: new Date(this.listConfig.region_sdate - 8640000),
            from: new Date(Date.now()),
          };
          if (
            this.listConfig.region_edate != "" &&
            this.listConfig.region_edate != null
          ) {
            this.updateDatePicker('');
          }
        });
      },
      changeEndDateRegion() {
        this.$nextTick(() => {
          this.updateDatePicker('');
        });
      },
      changeStartDateComfortLevel() {
        this.$nextTick(() => {
          this.isDisabledComfortLevel = false;
          this.disabledDates = {
            to: new Date(this.listConfig.comfortLevel_sdate - 8640000),
            from: new Date(Date.now()),
          };
          if (
            this.listConfig.comfortLevel_edate != "" &&
            this.listConfig.comfortLevel_edate != null
          ) {
            this.updateDatePicker('');
          }
        });
      },
      changeEndDateComfortLevel() {
        this.$nextTick(() => {
          this.updateDatePicker('');
        });
      },
      changeStartDateWorkArea() {
        this.$nextTick(() => {
          this.isDisabledWorkArea = false;
          this.disabledDates = {
            to: new Date(this.listConfig.workArea_sdate - 8640000),
            from: new Date(Date.now()),
          };
          if (
            this.listConfig.workArea_edate != "" &&
            this.listConfig.workArea_edate != null
          ) {
            this.updateDatePicker('');
          }
        });
      },
      changeEndDateWorkArea() {
        this.$nextTick(() => {
          this.updateDatePicker('');
        });
      },
      customFormatter(date) {
        return moment(date).format("MMM DD, YYYY");
      },
    },
  }
</script>